@charset "UTF-8";


// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "mixin" as mixin;
@use "color" as color;


// ==================================================
//  Page Top（index.ejs）
// ==================================================
.page-top {
  @include mixin.sp {
    width: 100%;
  }

  // Mainvisual
  .mainvisual {
    width: 100%;
    margin: 0 auto 0;

    @include mixin.sp {
      width: calc(100% - 64px);
      height: 271px;
    }

    &_item {
      @include mixin.sp {
        height: 100%;
      }
    }

    // Slick Slider
    .slick-list, .slick-track {
      @include mixin.sp {
        width: 100%;
        height: 100%;
        position: relative;
      }

      img {
        @include mixin.sp {
          width: auto !important;
          max-width: none;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  // Message
  .message {
    font-size: 2.0rem;
    font-weight: 600;
    margin: 74px 0 0;

    @include mixin.pc {
      text-align: center;
    }

    @include mixin.sp {
      width: calc(100% - 64px);
      margin: 0 auto;
      font-size: 1.5rem;
      margin-top: 35px;
    }
  }
}