@media screen and (max-width: 767px) {
  .page-top {
    width: 100%;
  }
}
.page-top .mainvisual {
  width: 100%;
  margin: 0 auto 0;
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual {
    width: calc(100% - 64px);
    height: 271px;
  }
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual_item {
    height: 100%;
  }
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual .slick-list, .page-top .mainvisual .slick-track {
    width: 100%;
    height: 100%;
    position: relative;
  }
}
@media screen and (max-width: 767px) {
  .page-top .mainvisual .slick-list img, .page-top .mainvisual .slick-track img {
    width: auto !important;
    max-width: none;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.page-top .message {
  font-size: 2rem;
  font-weight: 600;
  margin: 74px 0 0;
}
@media screen and (min-width: 768px) {
  .page-top .message {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .page-top .message {
    width: calc(100% - 64px);
    margin: 0 auto;
    font-size: 1.5rem;
    margin-top: 35px;
  }
}